html,
body,
.root,
.app {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-color: white;
}

.map-container {
  /* Standard */
  width: calc(100% - 300px);
  /* Firefox */
  width: -moz-calc(100% - 300px);
  /* WebKit */
  width: -webkit-calc(100% - 300px);
  /* Opera */
  width: -o-calc(100% - 300px);
  height: 100%;
  position: absolute;
  left: 300px;
  top: 0px;
  background-color: white;
}
#tripian-map {
  height: 100%;
}
.left-page {
  width: 300px;
  height: 100%;
  background-color: white;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: auto;
}
